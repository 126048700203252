import $ from 'jquery';

export default function () {
	$(document).ready(function() {
		var mySwiper = new Swiper('.swiper-container', {
		    speed: 600,
		    loop: true,
		    autoplay: 6000,
		    nextButton: '.swiper-button-next',
	        prevButton: '.swiper-button-prev'
		}); 
	});
}