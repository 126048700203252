import $ from 'jquery';
import './jquery.fullPage.min.js';
import './scrolloverflow.min.js';

export default function () {
	$(document).ready(function() {
		$('#fullpage').fullpage({
			anchors:['home', 'menu', 'resto', 'about', 'location'],
			menu: '.fixed-menu',
			normalScrollElements: '.tab-container, .tab-content'
		});

		$(document).on('click', '.button-pager.bottom', function() {
			$.fn.fullpage.moveSectionDown();
		});

		$(document).on('click', '.button-pager.top', function() {
			$.fn.fullpage.moveSectionUp();
		});

		calculateFitToSection();
		$(window).on('resize', function() {
			calculateFitToSection();
		});
	});
	
}

function calculateFitToSection() {
	if($(window).width() < 767 || $(window).height() < 650) {
		$.fn.fullpage.setFitToSection(false);
		$.fn.fullpage.setAutoScrolling(false);
		// $.fn.fullpage.setResponsive(false);
	}else {
		$.fn.fullpage.setFitToSection(true);
		$.fn.fullpage.setAutoScrolling(true);
		// $.fn.fullpage.setResponsive(true);
	}	
}