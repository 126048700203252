import $ from 'jquery';

export default function () {
	document.addEventListener("DOMContentLoaded", function(event) {
		 // When the window has finished loading create our google map below
	    google.maps.event.addDomListener(window, 'load', init);

	    function init() {
	        // Basic options for a simple Google Map
	        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
	        var mapOptions = {
	            // How zoomed in you want the map to start at (always required)
	            zoom: 11,

	            // The latitude and longitude to center the map (always required)
	            center: new google.maps.LatLng(40.6700, -73.9400), // New York

	            // How you would like to style the map. 
	            // This is where you would paste any style found on Snazzy Maps.
	            styles: [
				    {
				        "stylers": [
				            {
				                "saturation": -45
				            },
				            {
				                "lightness": 13
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#8fa7b3"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "color": "#667780"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#333333"
				            }
				        ]
				    },
				    {
				        "featureType": "road.highway",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "color": "#8fa7b3"
				            },
				            {
				                "gamma": 2
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#a3becc"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "color": "#7a8f99"
				            }
				        ]
				    },
				    {
				        "featureType": "road.arterial",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#555555"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#a3becc"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "geometry.stroke",
				        "stylers": [
				            {
				                "color": "#7a8f99"
				            }
				        ]
				    },
				    {
				        "featureType": "road.local",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#555555"
				            }
				        ]
				    },
				    {
				        "featureType": "water",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#bbd9e9"
				            }
				        ]
				    },
				    {
				        "featureType": "administrative",
				        "elementType": "labels.text.fill",
				        "stylers": [
				            {
				                "color": "#525f66"
				            }
				        ]
				    },
				    {
				        "featureType": "transit",
				        "elementType": "labels.text.stroke",
				        "stylers": [
				            {
				                "color": "#bbd9e9"
				            },
				            {
				                "gamma": 2
				            }
				        ]
				    },
				    {
				        "featureType": "transit.line",
				        "elementType": "geometry.fill",
				        "stylers": [
				            {
				                "color": "#a3aeb5"
				            }
				        ]
				    }
				]
	        };

	        // Get the HTML DOM element that will contain your map 
	        // We are using a div with id="map" seen below in the <body>
	        var mapElement = document.getElementById('map');

	        // Create the Google Map using our element and options defined above
	        var map = new google.maps.Map(mapElement, mapOptions);

	        var image = {
			    url: '/wp-content/themes/restocatch/assets/dist/images/pin-map.png',
			    // This marker is 20 pixels wide by 32 pixels high.
			    size: new google.maps.Size(96, 96),
			    // The origin for this image is (0, 0).
			    origin: new google.maps.Point(0, 0),
			    // The anchor for this image is the base of the flagpole at (0, 32).
			    anchor: new google.maps.Point(0, 47),
			    scaledSize: new google.maps.Size(47, 47)
			  };

	        // Let's also add a marker while we're at it
	        var marker = new google.maps.Marker({
	            position: new google.maps.LatLng(40.6700, -73.9400),
	            map: map,
	            title: 'Snazzy!',
	            icon: image
	        });
	    }
	});
}