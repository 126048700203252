import $ from 'jquery';

export default function () {

	//Fonction to strigger scroll to different desctions in a page
	$('.scrollTo').click(function(event){
		event.preventDefault();
		smoothScroll($(this).attr('href'));
		return false;
	});

	function smoothScroll(section){ // Smoothscroll to a section
		$('html, body').animate({ scrollTop:$("#"+section).offset().top }, 800);
	} 

	var $tabContainer = $('.tab-container');
	manageScrolMax();

	$tabContainer.scroll( function() {
		manageScrolMax();
	});

 	$(document).on('click', '.menu-buttons-container button', function() {
		if($(this).hasClass('less')){
			$tabContainer.animate({scrollTop: $tabContainer.scrollTop() - $tabContainer.height()}, 500, 'swing', function() {
				manageScrolMax();
			});
		}else {
			$tabContainer.animate({scrollTop: $tabContainer.scrollTop() + $tabContainer.height()}, 500, 'swing', function() {
				manageScrolMax();
			});
		}
	});

}

export function manageScrolMax() {
	var $tabContainer = $('.tab-container');
	if($tabContainer.scrollTop() === 0){
		$('.menu-buttons-container button.less').addClass('disabled');
		$('.menu-buttons-container button.more').removeClass('disabled');
	}else if (Math.round($tabContainer.scrollTop() + $tabContainer.height()) === $tabContainer[0].scrollHeight){
		$('.menu-buttons-container button.less').removeClass('disabled');
		$('.menu-buttons-container button.more').addClass('disabled');
	}else {
		$('.menu-buttons-container button').removeClass('disabled');
	}
}
