import $ from 'jquery';
import manageScrolMax from './scroller';

export default function () {
	$(document).ready(function() {
		$(document).on('click','.tab-menu button', function() {
			var dataTabId = $(this).attr('data-tab-id');
			$('.tab-menu button').removeClass('active')
			$(this).addClass('active');

			$('.tab-content').removeClass('active').hide();
			$('.tab-content[data-tab-id="'+dataTabId+'"] .menu-item').each(function(i,v) {
				$(this).css({
					'transition-delay': i*100+'ms',
					'-webkit-transition-delay': i*100+'ms',
					'-moz-transition-delay': i*100+'ms',
					'-o-transition-delay': i*100+'ms'
				});
			});
			$('.tab-content[data-tab-id="'+dataTabId+'"]').show();
			$('.tab-container').scrollTop(0);
			manageScrolMax();
			setTimeout(function() {
				$('.tab-content[data-tab-id="'+dataTabId+'"]').addClass('active');
			},100);
		});
	});
}