import './modules/helpers';
import start from './modules/router';
import mobileNav from './modules/mobileNav';
import loader from './modules/loader';
import scroller from './modules/scroller';
import fullpage from './modules/fullpage';
import tab from './modules/tab';
import map from './modules/map';
import swiper from './modules/swiper';

mobileNav();
loader();
scroller();
fullpage();
tab();
map();
swiper();